import MyNavbar from '../components/MyNavbar';
import MyFooter from '../components/MyFooter';

import Container from 'react-bootstrap/Container';

import './../index.css';

function Contact() {
  return (
    <div>
      {/* Navbar */}
      <div>
        <MyNavbar />
      </div>
      
      {/* Body */}
      <div>
        <div className="font-link">
          <Container align="center">
            <h1> Contact </h1>
            <h className="text-in-page">Reach out to me on Instagram @sofianeaf :)</h>
          </Container>
        </div>
      </div>

      {/* Footer */}
      <div>
        <MyFooter />
      </div>
    </div>
  );
}

export default Contact;