import Container    from 'react-bootstrap/Container';

import Nav          from 'react-bootstrap/Nav';
import Navbar       from 'react-bootstrap/Navbar';

import Button       from 'react-bootstrap/Button';

import 'bootstrap/dist/css/bootstrap.min.css';
import './../index.css';

function MyNavbar() {
  let mybutton;

  window.onscroll = function () {
    mybutton = document.getElementById("btn-back-to-top");
    scrollFunction(mybutton);
  };

  function scrollFunction(mybutton) {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }

  function backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <div>
      <div>
        {/* back to top button */}
        <Button
          onClick={backToTop}
          id='btn-back-to-top'
          className='navbar-back-to-top-btn'
          size='lg'
          type='checkbox'
        >up
        </Button>

        {/* navbar */}
        <Container className="p-2">
          <Navbar expand="lg">
            <Container align="center">
              <Navbar.Brand lassName="navbar-brand" href="/pages/Home">SA</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto navbar-items">
                  <Nav.Link href="/pages/Home">Home</Nav.Link>
                  <Nav.Link href="/pages/Photography">Photography</Nav.Link>
                  <Nav.Link href="https://www.instagram.com/sofiane.photo/">Instagram</Nav.Link>
                  <Nav.Link href="/pages/Painting">Painting</Nav.Link>
                  <Nav.Link href="/pages/Contact">Contact</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Container>        
      </div>
    </div>
  );
}

export default MyNavbar