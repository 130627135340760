import MyNavbar   from '../components/MyNavbar';
import MyGallery  from '../components/MyGallery';
import MyFooter from '../components/MyFooter';

import Container  from 'react-bootstrap/Container';

import './../index.css';

function Photography() {
  return (
    <div>
      {/* Navbar */}
      <div>
        <MyNavbar />
      </div>
      
      {/* Body */}
      <div>
        <div className="font-link">
          <Container align="center">
            <h1> Photography </h1>
          </Container>
        </div>

        <div>
          <MyGallery />
        </div>
      </div>

      {/* Footer */}
      <div>
        <MyFooter />
      </div>
    </div>
  );
}

export default Photography;