import React, { useEffect } from 'react';

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import './MyGallery.css';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import img_0_30 from './../photos/img_30/img_0_30.jpg';
import img_1_30 from './../photos/img_30/img_1_30.jpg';
import img_2_30 from './../photos/img_30/img_2_30.jpg';
import img_3_30 from './../photos/img_30/img_3_30.jpg';
import img_4_30 from './../photos/img_30/img_4_30.jpg';
import img_5_30 from './../photos/img_30/img_5_30.jpg';
import img_6_30 from './../photos/img_30/img_6_30.jpg';
import img_7_30 from './../photos/img_30/img_7_30.jpg';
import img_8_30 from './../photos/img_30/img_8_30.jpg';
import img_9_30 from './../photos/img_30/img_9_30.jpg';
import img_10_30 from './../photos/img_30/img_10_30.jpg';
import img_11_30 from './../photos/img_30/img_11_30.jpg';
import img_12_30 from './../photos/img_30/img_12_30.jpg';
import img_13_30 from './../photos/img_30/img_13_30.jpg';
import img_14_30 from './../photos/img_30/img_14_30.jpg';
import img_15_30 from './../photos/img_30/img_15_30.jpg';
import img_16_30 from './../photos/img_30/img_16_30.jpg';
import img_17_30 from './../photos/img_30/img_17_30.jpg';

import img_0_50 from './../photos/img_50/img_0_50.jpg';
import img_1_50 from './../photos/img_50/img_1_50.jpg';
import img_2_50 from './../photos/img_50/img_2_50.jpg';
import img_3_50 from './../photos/img_50/img_3_50.jpg';
import img_4_50 from './../photos/img_50/img_4_50.jpg';
import img_5_50 from './../photos/img_50/img_5_50.jpg';
import img_6_50 from './../photos/img_50/img_6_50.jpg';
import img_7_50 from './../photos/img_50/img_7_50.jpg';
import img_8_50 from './../photos/img_50/img_8_50.jpg';
import img_9_50 from './../photos/img_50/img_9_50.jpg';
import img_10_50 from './../photos/img_50/img_10_50.jpg';
import img_11_50 from './../photos/img_50/img_11_50.jpg';
import img_12_50 from './../photos/img_50/img_12_50.jpg';
import img_13_50 from './../photos/img_50/img_13_50.jpg';
import img_14_50 from './../photos/img_50/img_14_50.jpg';
import img_15_50 from './../photos/img_50/img_15_50.jpg';
import img_16_50 from './../photos/img_50/img_16_50.jpg';
import img_17_50 from './../photos/img_50/img_17_50.jpg';


import data from './data.json'
import { Container } from 'react-bootstrap';

const sg_par = {
  galleryID : "my-gallery",
  images : [
    {
      largeURL    : img_0_30,
      thumbnailURL: img_0_50,
      width       : 3744,
      height      : 5616,
    },
    {
      largeURL    : img_1_30,
      thumbnailURL: img_1_50,
      width       : 3744,
      height      : 5616,
    },
    {
      largeURL    : img_2_30,
      thumbnailURL: img_2_50,
      width       : 3631,
      height      : 5446,
    },
    {
      largeURL    : img_3_30,
      thumbnailURL: img_3_50,
      width       : 3744,
      height      : 5616,
    },
    {
      largeURL    : img_4_30,
      thumbnailURL: img_4_50,
      width       : 3659,
      height      : 5489,
    },
    {
      largeURL    : img_5_30,
      thumbnailURL: img_5_50,
      width       : 3259,
      height      : 4889,
    },
    {
      largeURL    : img_6_30,
      thumbnailURL: img_6_50,
      width       : 3298,
      height      : 4947,
    },
    {
      largeURL    : img_7_30,
      thumbnailURL: img_7_50,
      width       : 3520,
      height      : 5278,
    },
    {
      largeURL    : img_8_30,
      thumbnailURL: img_8_50,
      width       : 3636,
      height      : 5520,
    },
    {
      largeURL    : img_9_30,
      thumbnailURL: img_9_50,
      width       : 3422,
      height      : 5134,
    },
    {
      largeURL    : img_10_30,
      thumbnailURL: img_10_50,
      width       : 2762,
      height      : 4144,
    },
    {
      largeURL    : img_11_30,
      thumbnailURL: img_11_50,
      width       : 3456,
      height      : 5184,
    },
    {
      largeURL    : img_12_30,
      thumbnailURL: img_12_50,
      width       : 3312,
      height      : 4968,
    },
    {
      largeURL    : img_13_30,
      thumbnailURL: img_13_50,
      width       : 3744,
      height      : 5616,
    },
    {
      largeURL    : img_14_30,
      thumbnailURL: img_14_50,
      width       : 3410,
      height      : 5115,
    },
    {
      largeURL    : img_15_30,
      thumbnailURL: img_15_50,
      width       : 3216,
      height      : 4824,
    },
    {
      largeURL    : img_16_30,
      thumbnailURL: img_16_50,
      width       : 3744,
      height      : 5616,
    },
    {
      largeURL    : img_17_30,
      thumbnailURL: img_17_50,
      width       : 3744,
      height      : 5616,
    },
  ]
}

/* gallery */

  const newData = data.map((img,idx) => {
    console.log(idx,img)
  });



function MyGallery() {
  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      // to apply styles just to this instance of PhotoSwipe
      mainClass : 'pswp--my-class',
      gallery   : '#' + sg_par.galleryID,
      children  : 'a',

      //arrowPrev : false,
      //arrowNext : false,
      zoom      : false,
      //close     : false,
      //counter   : false,

      //closeTitle        : 'Close',
      //zoomTitle         : 'Zoom',
      //arrowPrevTitle    : 'Previous',
      //arrowNextTitle    : 'Next',
      //errorMsg          : 'The image cannot be loaded',
      //indexIndicatorSep : ' / ',
      
      /* my customization (https://photoswipe.com/options/) */
      bgOpacity                 : 0.8,
      //spacing                 : 0.5, // 50% of viewport width
      //allowPanToNext          : false,
      loop                      : false,
      //wheelToZoom               : true,
      //pinchToClose            : false,
      //closeOnVerticalDrag     : false,
      //escKey                  : false,
      //arrowKeys               : false,
      //returnFocus             : false,
      //clickToCloseNonZoomable : false,
      //preloadFirstSlide       : false,
      //preloaderDelay          : 2000,
      indexIndicatorSep         : ' of ',
      //preload                 : [1, 4],
      //maxWidthToAnimate       : 800,

      /* padding */
      padding: { top: 20, bottom: 40, left: 100, right: 100 },

      pswpModule: () => import('photoswipe'),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <div className="pswp-gallery" id={sg_par.galleryID}>

      <div>
        <Container lassName="p-1" align="center">
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className="p-1" align="center" > <MyPhoto index = {0} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {1} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {2} /> </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container lassName="p-1" align="center">
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className="p-1" align="center" > <MyPhoto index = {3} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {4} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {5} /> </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container lassName="p-1" align="center">
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className="p-1" align="center" > <MyPhoto index = {6} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {7} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {8} /> </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container lassName="p-1" align="center">
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className="p-1" align="center" > <MyPhoto index = {9} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {10} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {11} /> </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container lassName="p-1" align="center">
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className="p-1" align="center" > <MyPhoto index = {12} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {13} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {14} /> </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container lassName="p-1" align="center">
          <Row>
            <Col></Col>
            <Col xs="auto">
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className="p-1" align="center" > <MyPhoto index = {15} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {16} /> </Col>
                <Col className="p-1" align="center" > <MyPhoto index = {17} /> </Col>
              </Row>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

const thumbnailWidth = 300;

function MyPhoto(props) {
  return (
    <a
      class           ="a-gallery"
      href            ={sg_par.images[props.index].largeURL}
      data-pswp-width ={sg_par.images[props.index].width}
      data-pswp-height={sg_par.images[props.index].height}
      key             ={sg_par.galleryID + '-' + props.index}
      target          ="_blank"
      rel             ="noreferrer"
    >
      <img
        width         ={thumbnailWidth}
        height        ={(sg_par.images[props.index].height/(sg_par.images[props.index].width/thumbnailWidth)).toString()}
        src           ={sg_par.images[props.index].thumbnailURL}
        alt           =""
      />
    </a>
  );
}


export default MyGallery