import Container    from 'react-bootstrap/Container';
import {SocialIcon} from 'react-social-icons'; //https://www.npmjs.com/package/react-social-icons

import 'bootstrap/dist/css/bootstrap.min.css';

function MyFooter() {
  

  return (
    <div>
      <Container className="p-2" align="center">
        <a style={{ padding:3 }}>
          <SocialIcon
            url="https://instagram.com/sofiane.photo"
            network="instagram"
            bgColor="#87bdd8"
            style={{ height: 40, width: 40 }}
          />
        </a>
        <a style={{ padding:3 }}>
          <SocialIcon 
            url="/pages/Contact"
            network="email"
            bgColor="#000000"
            style={{ height: 40, width: 40 }}
          />
        </a>
      </Container>
    </div>
  );
}

export default MyFooter