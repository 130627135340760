import React        from 'react';
import ReactDOM     from 'react-dom/client';

import Home         from './pages/Home';
import Photography  from "./pages/Photography";
import Painting     from "./pages/Painting";
import Contact      from "./pages/Contact";

import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById('root')
  );

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/"                 element={<Home />} />
        <Route path="pages/Home"        element={<Home />} />
        <Route path="pages/Photography" element={<Photography />} />
        <Route path="pages/Painting"    element={<Painting />} />
        <Route path="pages/Contact"     element={<Contact />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

