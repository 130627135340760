import Nav          from 'react-bootstrap/Nav';
import Container    from 'react-bootstrap/Container';
import Col          from 'react-bootstrap/Col';
import Row          from 'react-bootstrap/Row';

import {SocialIcon} from 'react-social-icons'; //https://www.npmjs.com/package/react-social-icons

import Card from 'react-bootstrap/Card';

function Home() {
  return (
    <div className="home-bg home-vertical-center">
      <Container>
        <Row>
          <Col></Col>
          <Col>
            <Card className="home-card">

              <Container align="center">
                <h1 className="home-name"> Sofiane Affes </h1>
                <h className="home-phrase"> Artist based in Munich, Germany. </h>
              </Container>

              <Nav className="justify-content-center">
                <Nav.Item>
                  <Nav.Link className="home-nav" href="/pages/Photography">Photography</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="home-nav" href="/pages/Painting">Painting</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link className="home-nav" href="https://www.instagram.com/sofiane.photo/">Instagram</Nav.Link>
                </Nav.Item>
              </Nav>

              <Container className="p-2" align="center">
                <a style={{ padding:3 }}>
                  <SocialIcon
                    url="https://instagram.com/sofiane.photo"
                    network="instagram"
                    bgColor="#ffffff"
                    style={{ height: 40, width: 40 }}
                  />
                </a>
                <a style={{ padding:3 }}>
                  <SocialIcon 
                    url="/pages/Contact"
                    network="email"
                    bgColor="#ffffff"
                    style={{ height: 40, width: 40 }}
                  />
                </a>
              </Container>

            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>      
    </div>
  );
}

export default Home;
